@import-normalize; /*INFO: Although this line marked as "Unknown CSS-at rule on IntelliJ, It works properly."*/
html,
body * {
  box-sizing: border-box;
}

input:-webkit-autofill::first-line {
  /*Fixes Chrome Autofill font size, fontfamily bug*/
  font-family: SansSerif, sans-serif;
  font-size: 15px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
